import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { injectJwtTokenIntoHeaders } from '../tokenLocalStorage';

import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { ITaskAPI, ICreateTaskRequest, ITaskSearchFilter, ITasksListSort } from './types/TaskManagementResponse';
import { IPagination } from 'store/common/types';

export enum TaskManagementEndpoints {
  CATEGORIES = 'v1/categories',
  TEAMS = 'v1/teams',
  TASKS = 'v1/tasks',
  COMMENTS = 'v1/comments',
}

export class TaskManagementApiService<T extends AxiosWrapper<AxiosInstance>> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getTaskCategoriesInfo = async (): Promise<AxiosResponse> => {
    return this.client.get(TaskManagementEndpoints.CATEGORIES);
  };

  getTaskTeamsInfo = async (): Promise<AxiosResponse> => {
    return this.client.get(TaskManagementEndpoints.TEAMS);
  };
  
  getTasks = async ({
    pagination,
    sort,
    filter,
  }: {
    sort: ITasksListSort;
    filter?: Partial<ITaskSearchFilter>;
    pagination?: IPagination;
  }): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.post(`${TaskManagementEndpoints.TASKS}/search`, {
      sort,
      filter,
      pagination,
    });
  };

  deleteTask = async (taskId: string): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.delete(`${TaskManagementEndpoints.TASKS}/${taskId}`);
  };
  
  getTask = async (taskId: string): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.get(`${TaskManagementEndpoints.TASKS}/${taskId}`);
  };

  editTask = async (taskId: string, taskToUpdate: ICreateTaskRequest): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.put(`${TaskManagementEndpoints.TASKS}/${taskId}`, taskToUpdate);
  };
  
  createTask = async (taskToCreate: ICreateTaskRequest): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.post(TaskManagementEndpoints.TASKS, taskToCreate);
  };

  createTaskComment = async (taskId: string, comment: string): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.post(`${TaskManagementEndpoints.TASKS}/${taskId}/comments`, { content: comment });
  };

  listTaskComments = async (taskId: string): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.get(`${TaskManagementEndpoints.TASKS}/${taskId}/comments`);
  };
  
  deleteComment = async (commentId: string): Promise<AxiosResponse<ITaskAPI>> => {
    return this.client.delete(`${TaskManagementEndpoints.COMMENTS}/${commentId}`);
  };
}

export const makeTaskManagementApi = (travelAgentUuid?: string): TaskManagementApiService<AxiosWrapper<AxiosInstance>> => {
  const dynamicParameters = ParameterService.getParameters();
  const headers: any = {};

  const client = axios.create({
    baseURL: dynamicParameters.TASK_MANAGER_BASE_URL,
    headers: injectJwtTokenIntoHeaders(headers),
    withCredentials: true,
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });

  client.defaults.params = {
    travelAgentUuid: travelAgentUuid,
  };

  const clientWrapper = new AxiosWrapper(client);

  return new TaskManagementApiService<AxiosWrapper<AxiosInstance>>(clientWrapper);
};
