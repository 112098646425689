import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { saveAs } from 'file-saver';
import { enquiryCreatedNotificationMessage } from './common';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { BookingStatusTypes } from 'config/enums';

export const handleSaveBooking = async ({
  bookingBuild,
  bookingDomain,
  backendApi,
  clientCountryCode,
  selectedCompanyMembership,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  taMarginAmountOverride,
}) => {
  const attr = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({ bookingDomain });
  if (bookingBuild) {
    attr.bookingBuild = bookingBuild.request;
    attr.bookingHash = bookingBuild.response.bookingHash;
  }
  if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
    attr.bookingInformation.taMarginAmount = taMarginAmountOverride;
  }
  try {
    const booking = await backendApi.createBooking(attr, null, clientCountryCode, selectedCompanyMembership);
    setShouldShowLeaveAlert(false);
    setRedirectLocation(`/booking-manager/${booking.uuid}`);
  } catch (e) {
    throw new Error(e);
  }
};

export const handleDownloadQuote = async props => {
  const {
    bookingBuild,
    bookingDomain,
    backendApi,
    bookingManagerApi,
    enqueueNotification,
    clientCountryCode,
    quoteMrginLogoOption,
    selectedCompanyMembership,
    taMarginAmountOverride,
  } = props;

  try {
    const attr = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({ bookingDomain });
    if (bookingBuild) {
      attr.bookingBuild = bookingBuild.request;
      attr.bookingHash = bookingBuild.response.bookingHash;
    }
    if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
      attr.bookingInformation.taMarginAmount = taMarginAmountOverride;
    }
    const booking = await backendApi.createBooking(attr, null, clientCountryCode, selectedCompanyMembership);

    enqueueNotification({
      message: enquiryCreatedNotificationMessage(booking),
      options: { variant: 'success' },
    });

    let upload = { url: '', filename: '' };
    if (quoteMrginLogoOption === EPdfLogo.MAIN_COMPANY_LOGO || quoteMrginLogoOption === null) {
      const { data } = await bookingManagerApi.generateBreakdownPdfForTa(booking.uuid);
      upload = data;
    } else {
      const { data } = await bookingManagerApi.generateBreakdownPdfForClient(booking.uuid, quoteMrginLogoOption);
      upload = data;
    }

    saveAs(upload.url, upload.filename);
  } catch (err) {
    enqueueNotification({
      message: 'Failed to generate a quote to download',
      options: { variant: 'error' },
    });

    throw err;
  }
};

export const handleRequestBooking = async ({
  bookingBuild,
  bookingDomain,
  backendApi,
  liveRatePricesModalData = false,
  clientCountryCode,
  selectedCompanyMembership,
  isInstantBook = false,
  bbv1AddBookingError,
  bbv1ClearBookingErrors,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  paymentMethod,
  taMarginAmountOverride,
}) => {
  let attr = {
    ...BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({
      bookingDomain,
      bookingStatus: BookingStatusTypes.REQUESTED,
      paymentMethod,
    }),
    isInstantBook,
  };
  if (bookingBuild) {
    attr.bookingBuild = bookingBuild.request;
    attr.bookingHash = bookingBuild.response.bookingHash;
  }

  if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
    attr.bookingInformation.taMarginAmount = taMarginAmountOverride;
  }

  // merge new prices coming from priceCheck endpoint
  const bookingAttributes = !liveRatePricesModalData
    ? attr
    : {
        ...attr,
        bookingBuild: {
          ...attr.bookingBuild,
          Accommodation: attr.bookingBuild!.Accommodation.map((acc, index) => ({
            ...acc,
            liveRate: {
              ...acc.liveRate,
              amount: liveRatePricesModalData[index].newPrice ?? liveRatePricesModalData[index].prevPrice,
            },
          })),
        },
      };

  try {
    bbv1ClearBookingErrors();
    const booking = await backendApi.createBooking(
      bookingAttributes,
      null,
      clientCountryCode,
      selectedCompanyMembership
    );

    setShouldShowLeaveAlert(false);
    setRedirectLocation(`/booking-manager/${booking.uuid}`);
  } catch (e) {
    console.log('error');
    e.response.data.errors?.map(error => bbv1AddBookingError(error));
    throw e;
  }
};
