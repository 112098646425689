import { EUserType } from 'services/BackendApi';

export interface IActingOnBehalfOfDomain {
  aoboUuid: string | null;
  aoboRole: EUserType | null;
  aoboAssociatedTaUuid: string | null;
}

export const initialState: IActingOnBehalfOfDomain = {
  aoboUuid: null,
  aoboRole: null,
  aoboAssociatedTaUuid: null,
};
