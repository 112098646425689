import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CANCEL } from 'redux-saga';
import { without, reject, isNil } from 'ramda';
import qs from 'qs';
import {
  ISearchQuery,
  OffersSearchSuccessResponse,
  MealPlanNames,
  ErrorResponse,
  SearchOptionsResponse,
  BookingBuilderRequest,
  BookingBuilderResponse,
  ICompanyLogoResponse,
  IUploadResponse,
  IUploadListResponse,
  IPreviewParams,
  IProposalPreviewResponse,
  ISalesRepresentativesResponse,
  LiveRatesResponse,
  ICompanyResponse,
  ICompanyInvoiceAddresseeResponse,
  ICompanyInvoiceAddresseeListResponse,
  IInvoiceAddressee,
  IGuestAgesItem,
  IPriceCheckReq,
  IGetCredentialsInfo,
  IGetCredentialsInfoResponse,
  IPostCredentialsInfo,
  IPage,
  ICsvResponse,
  ISalesRepresentative,
  EUserType,
  IInternalUsersFilter,
  EGenericStatusValue,
} from './types';

// Move to backkendApiService
import { ALL_COUNTRIES_AND_RESORTS } from 'store/modules/fastSearch/constants';
import { Lodging, PriceRange, StarRating } from './types/SearchQuery';
import { IBookingAttributes, TCountryCode } from 'interfaces';
import { NewProposalPayload } from 'store/modules/bookingBuilder';
import { IProposalsListResponse } from './types/ProposalsListResponse';
import { IBookingsListResponse } from './types/BookingsListResponse';
import { ITravelAgent, ITravelAgentFilter, ITravelAgentResponse, IUserFilter } from './types/TravelAgentResponse';
import { IInternalUser, IInternalUserResponse } from './types/InternalUserResponse';
import { IHotelNamesResponse } from './types/HotelNamesResponse';
import { ICompaniesResponse, ICompany } from './types/CompaniesResponse';
import { IOffersListResponse, IOffersDeleteResponse } from './types/OffersListResponse';
import {
  IOfferResponse,
  IOfferAPI,
  IOffersOnHotelResponse,
  IAccommodationProductForHotelItem,
  IOffersSortPayload,
} from './types/OfferResponse';
import { transformPut, transformPost, toApiPayload, buildQuery } from './helpers';
import { IApiErrorResponse } from './types/ApiError';
import { IAPIRepsonse } from './types/ApiResponse';
import { IImportResponse } from './types/ImportResponse';
import { IHotel } from './types/HotelResponse';
import { IGetBookingResponse } from './types/BookingManagerResponse';
import { ICompanyLogoPosition } from '../../store/modules/companyInfo/model';
import { injectJwtTokenIntoHeaders } from '../tokenLocalStorage';
import { IPostRateBreakRequest } from 'store/modules/rateBreak/types';
import { CompanyDocumentsSortField, CompanyDocumentsSortOrder } from 'store/modules/companyDocuments/model';
import { ICompanyListFilter } from 'store/modules/companyList/model';

import {
  IEnabledNotification,
  IEnabledNotificationsResponse,
  IPossibleNotificationsResponse,
} from './types/Notification';
import { ITravelAgentListDomain } from 'store/modules/travelAgentList/model';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import { ILiveRatesListingFilter, ILiveRatesSettingsInternalRoomsItem } from './types/LiveRatesInternal';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';

import { ITokenSet } from 'services/CognitoApi';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { omitDeep } from 'deepdash-es/standalone';
import { ICreateBookingProcessResponse, ICreateBookingRequest } from './types/Booking';
import { IBasketBuildL4, IBasketUserResponseItem, IGetBasketResponse } from './types/Basket';
export enum BackendEndpoints {
  SEARCH_OPTIONS = 'search/options',
  SEARCH = 'search',
  NAMES = 'search/names',
  BOOKING_BUILDER = 'booking-builder',
  CONTENT = 'contents',
  HOTEL = 'hotels',
  BOOKINGS = 'bookings',
  PROPOSALS = 'proposals',
  OFFERS = 'offers',
  PRODUCTS = 'products',
  COUNTRIES = 'countries',
  RATES_LOADER = 'rates-loader',
  PRODUCTS_LOADER = 'products-loader',
  COMPANIES = 'companies',
  USERS = 'users',
  USERS_SEARCH = 'users/search',
  UPLOAD = 'uploads',
  LIVE_RATES = 'live-rates',
  RATE_BREAK = 'rate-breaks',
  COMPANY_HOTEL_MARKUPS = 'company-hotel-markups',
  COMPANY_HOTEL_DISABLE = 'company-hotel-disable',
  INVOICE_ADDRESSEES = 'invoice-addressees',
  STATIC_RATES_AVAILABILITY = 'static-rates-availability',
  BOOKING_COM_RATES = 'booking-com-rates',
  NOTIFICATIONS = 'notifications',
  COMPANY_DEPARTMENTS = 'company-departments',
  COMPANY_MEMBERSHIPS = 'company-memberships',
  BASKET = 'basket',
}

export class BackendApiService<T extends AxiosWrapper<AxiosInstance>> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getSearchOptions = async (): Promise<AxiosResponse<SearchOptionsResponse>> =>
    this.client.get(BackendEndpoints.SEARCH_OPTIONS);

  fetchCompany = async (companyUuid: string): Promise<AxiosResponse<ICompanyResponse>> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}`;
    return this.client.get(endpoint);
  };

  getCompanies = async (queryOverride = {}): Promise<AxiosResponse<ICompaniesResponse>> => {
    const query = {
      sort: 'company.name',
      ...queryOverride,
    };

    return this.client.get(BackendEndpoints.COMPANIES, { params: query });
  };

  getTravelCompaniesCsv = async (filter: ICompanyListFilter): Promise<AxiosResponse<ICsvResponse>> => {
    const params = reject(isNil, {
      nameLike: filter.search,
      countryCode: filter.countryCode,
    });

    return this.client.post(`${BackendEndpoints.COMPANIES}/csv`, params);
  };

  createCompany = async (companyData: Partial<ICompany>): Promise<AxiosResponse<ICompaniesResponse>> => {
    return this.client.post(`${BackendEndpoints.COMPANIES}`, toApiPayload(companyData, { type: 'company' }));
  };

  updateCompany = async (
    companyUuid: string,
    companyData: Partial<ICompany>
  ): Promise<AxiosResponse<ICompanyResponse>> => {
    return this.client.put(
      `${BackendEndpoints.COMPANIES}/${companyUuid}`,
      toApiPayload(companyData, { id: companyUuid, type: 'company' })
    );
  };

  patchCompany = async (
    companyUuid: string,
    companyData: Partial<ICompany>
  ): Promise<AxiosResponse<ICompanyResponse>> => {
    return this.client.patch(
      `${BackendEndpoints.COMPANIES}/${companyUuid}`,
      toApiPayload(companyData, { id: companyUuid, type: 'company' })
    );
  };

  getOffersSearch = async (
    query: ISearchQuery
  ): Promise<AxiosResponse<OffersSearchSuccessResponse | ErrorResponse>> => {
    const endpoint = `${BackendEndpoints.SEARCH}`;
    for (const key in query) {
      if (query[key] === '') {
        delete query[key];
      }
    }
    return this.client.get(endpoint, { params: query });
  };

  getNamesSearch = async (name: string): Promise<AxiosResponse<ErrorResponse | ErrorResponse>> => {
    const params = {
      name,
    };

    return this.client.get(BackendEndpoints.NAMES, { params });
  };

  getHotel = async (hotelUuid: string, associations?: string[]): Promise<AxiosResponse<IHotel | ErrorResponse>> => {
    const endpoint = `${BackendEndpoints.HOTEL}/${hotelUuid}`;
    return this.client.get(endpoint, {
      params: {
        associations,
      },
    });
  };

  patchHotel = async (hotelUuid: string, payload: any): Promise<AxiosResponse<IHotel | ErrorResponse>> => {
    const endpoint = `${BackendEndpoints.HOTEL}/${hotelUuid}`;
    return this.client.patch(endpoint, toApiPayload(payload, { id: hotelUuid, type: 'hotel' }));
  };

  getContentLatestOffers = async (): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.CONTENT}`;
    const params = {
      associations: 'uploads',
      filter: {
        content: {
          type: 'latest-offer',
          enabled: true,
        },
      },
      page: {
        limit: 3,
      },
      sort: '-content.createdAt',
    };

    return this.client.get(endpoint, { params });
  };

  postBookingBuilderRequest = async (
    bookingBuilderRequest: BookingBuilderRequest,
    clientCountryCode?: TCountryCode,
    travelAgentUuid?: string,
    validateStatusFunc?: (status: number) => boolean,
    actingOnBehalfOfUserUuid?: string
  ): Promise<AxiosResponse<BookingBuilderResponse | ErrorResponse>> => {
    let endpoint = `${BackendEndpoints.BOOKING_BUILDER}`;
    const params: any = {};
    if (clientCountryCode) {
      params.clientCountryCode = clientCountryCode;
    }
    if (travelAgentUuid) {
      params.travelAgentUuid = travelAgentUuid;
    }
    if (actingOnBehalfOfUserUuid) {
      params.actingOnBehalfOfUserUuid = actingOnBehalfOfUserUuid;
    }
    return this.client.post(endpoint, toApiPayload(omitDeep(bookingBuilderRequest, ['availableToInstantBook'])), {
      params,
      validateStatus: validateStatusFunc !== undefined ? validateStatusFunc : status => status >= 200 && status < 300,
    });
  };

  getAvailableProposals = async () => {
    const endpoint = `proposals/available`;
    return this.client.get(endpoint);
  };

  getProposalsList = async (query): Promise<AxiosResponse<IProposalsListResponse>> => {
    return this.client.get(BackendEndpoints.PROPOSALS, { params: query });
  };

  completeProposal = async (proposalUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.PROPOSALS}/${proposalUuid}/complete`;
    return this.client.post(endpoint, {});
  };

  updateProposal = async (proposalUuid: string, payload): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.PROPOSALS}/${proposalUuid}`;
    return this.client.patch(endpoint, toApiPayload(payload));
  };

  getPendingProposalsInfo = async (): Promise<AxiosResponse<IProposalsListResponse>> => {
    const params = {
      fields: {
        proposal: 'uuid,guestTitle,guestFirstName,guestLastName',
      },
      filter: {
        proposal: {
          isLocked: false,
        },
      },
      page: {
        limit: 1,
      },
      sort: '-proposal.createdAt',
    };

    return this.client.get(BackendEndpoints.PROPOSALS, { params });
  };

  getBookingsList = async (query): Promise<AxiosResponse<IBookingsListResponse>> => {
    return this.client.get(BackendEndpoints.BOOKINGS, { params: query });
  };

  getBooking = async (uuid: string, associations: string[] = []): Promise<AxiosResponse<IGetBookingResponse>> => {
    const endpoint = `${BackendEndpoints.BOOKINGS}/${uuid}`;
    const params: { associations?: string } = {};
    if (associations.length >= 1) {
      params.associations = associations.join(',');
    }
    return this.client.get(endpoint, { params });
  };

  login = async (payload): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/login`;
    return this.client.post(endpoint, toApiPayload(payload));
  };

  logout = async (): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/logout`;
    return this.client.post(endpoint);
  };

  getUserMe = async (params): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/me`;
    return this.client.get(endpoint, { params });
  };

  signup = async (payload): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/signup`;
    return this.client.post(endpoint, toApiPayload(payload));
  };

  resetPassword = async (payload): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/reset-password`;
    return this.client.post(endpoint, toApiPayload(payload));
  };

  setPassword = async (payload): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/set-password`;
    return this.client.patch(endpoint, toApiPayload(payload));
  };

  updatePassword = async (payload): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/update-password`;
    return this.client.patch(endpoint, toApiPayload(payload));
  };

  getTravelAgents = async (): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const params = {
      filter: {
        user: {
          type: EUserType.TA,
        },
      },
      sort: 'user.lastName,user.firstName,user.title',
    };
    return this.client.get(BackendEndpoints.USERS, { params });
  };

  getTravelAgentsByCompanyId = async (
    companyUuid: string,
    includeNotifications: boolean
  ): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const params = {
      associations: includeNotifications ? 'notifications' : undefined,
      filter: {
        user: {
          type: EUserType.TA,
          companyUuid,
        },
      },
    };

    const endpoint = `${BackendEndpoints.USERS}`;
    return this.client.get(endpoint, { params });
  };

  getTravelAgentsByCompanyIdInTC = async (companyUuid: string): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const endpoint = `${BackendEndpoints.USERS}/travel-company/${companyUuid}/travel-agents`;
    return this.client.get(endpoint);
  };

  buildTravelAgentUserFilter = (filter: ITravelAgentFilter): IUserFilter => {
    const search = 'firstName,lastName,email:ilike';

    const user: IUserFilter & { companyUuid?: string | null } = { type: EUserType.TA };

    if (filter.countryCode) {
      user.countryCode = filter.countryCode;
    }

    if (filter.companyUuid) {
      user.companyUuid = filter.companyUuid;
    }
    if (filter.status) {
      user.status = filter.status;
    }
    if (filter.search) {
      user[search] = filter.search;
    }

    return user;
  };

  getTravelAgentList = async (
    filter: ITravelAgentFilter,
    page: IPage | undefined,
    userType: EUserType = EUserType.ADMIN,
    sortObject: ITravelAgentListDomain['sort'],
    isSRIn?: boolean
  ): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const { countryCode, salesRepUuid, search, status, companyUuid, createdBy, email } = filter;

    const { field, order } = sortObject;
    let sort = `${field}`;
    if (order === 'desc') {
      sort = `-${field}`;
    }

    return this.client.post(BackendEndpoints.USERS_SEARCH, {
      filter: {
        type: [EUserType.TA],
        countryCode: countryCode ? countryCode : undefined,
        term: search ? search : undefined,
        status: status ? status : undefined,
        salesRepresentativeUserUuid: salesRepUuid ? salesRepUuid : undefined,
        companyUuid: companyUuid ? companyUuid : undefined,
        createdBy: createdBy ? createdBy : undefined,
        isSRIn: isSRIn !== undefined && isSRIn,
        email: email ? email : undefined,
      },
      page,
      sort,
    });
  };

  massAssignAdd = async (
    filter: ITravelAgentFilter,
    srUuids: string[]
  ): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const { countryCode, salesRepUuid, search, status } = filter;

    return this.client.post(`${BackendEndpoints.USERS}/transfer-assignments/update`, {
      filter: {
        type: [EUserType.TA],
        countryCode: countryCode ? countryCode : undefined,
        term: search ? search : undefined,
        status: status ? status : undefined,
        salesRepresentativeUserUuid: salesRepUuid ? salesRepUuid : undefined,
      },
      srUuids,
    });
  };

  massAssignOverwrite = async (
    filter: ITravelAgentFilter,
    srUuids: string[]
  ): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const { countryCode, salesRepUuid, search, status } = filter;

    return this.client.post(`${BackendEndpoints.USERS}/transfer-assignments/replace`, {
      filter: {
        type: [EUserType.TA],
        countryCode: countryCode ? countryCode : undefined,
        term: search ? search : undefined,
        status: status ? status : undefined,
        salesRepresentativeUserUuid: salesRepUuid ? salesRepUuid : undefined,
      },
      srUuids,
    });
  };

  getTravelAgentsCsv = async (filter: ITravelAgentFilter): Promise<AxiosResponse<ICsvResponse>> => {
    const user = this.buildTravelAgentUserFilter(filter);

    const params = {
      filter: { user },
    };

    return this.client.get(`${BackendEndpoints.USERS}/travel-agents/csv`, { params });
  };

  getInternalUsersList = async (
    filter: IInternalUsersFilter,
    page: IPage
  ): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const { userRole, countryCode, search, status, email } = filter;
    return this.client.post(BackendEndpoints.USERS_SEARCH, {
      filter: {
        type: userRole ? [userRole] : [EUserType.SR, EUserType.ADMIN, EUserType.FINANCE, EUserType.RL],
        countryCode: countryCode ? countryCode : undefined,
        term: search ? search : undefined,
        status: status ? status : undefined,
        email: email ? email : undefined,
      },
      page,
      sort: '-createdAt',
    });
  };

  getInternalUsersCsv = async (filter: IInternalUsersFilter) => {
    const { userRole, countryCode, search, status } = filter;
    return this.client.post(`${BackendEndpoints.USERS}/internal-users/csv`, {
      filter: {
        type: userRole ? [userRole] : [EUserType.SR, EUserType.ADMIN, EUserType.FINANCE, EUserType.RL],
        countryCode: countryCode ? countryCode : undefined,
        term: search ? search : undefined,
        status: status ? status : undefined,
      },
    });
  };

  // TODO: at the moment userData describes ITravelAgent and ISalesRepresentative types, maybe need to add other types
  createUser = async (
    userData: Partial<ITravelAgent> | Partial<ISalesRepresentative>,
    notifications: IEnabledNotification[]
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}`;
    userData.notifications = notifications;
    return this.client.post(endpoint, toApiPayload(userData));
  };

  createUserRequest = async (userData: Partial<ITravelAgent>): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/request`;
    delete userData.status;
    delete userData.type;
    delete userData.isExistingPartner;
    delete userData.companyBookingManager;
    delete userData.companyTaManager;
    return this.client.post(endpoint, userData);
  };

  getInternalUsersByEmail = async (email: string): Promise<AxiosResponse<IInternalUserResponse>> => {
    const params = {
      filter: { user: { email } },
    };
    return this.client.get(BackendEndpoints.USERS, { params });
  };

  createInternalUser = async (
    userData: Partial<IInternalUser>,
    notifications: IEnabledNotification[]
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}`;
    userData.notifications = notifications;
    return this.client.post(endpoint, toApiPayload(userData));
  };

  // TODO: at the moment userData describes ITravelAgent and ISalesRepresentative types, maybe need to add other types
  updateUser = async (
    uuid: string,
    userData: Partial<ITravelAgent> | Partial<ISalesRepresentative>,
    notifications: IEnabledNotification[],
    userType: EUserType
  ): Promise<AxiosResponse> => {
    const endpointMapping = {
      [EUserType.ADMIN]: `${BackendEndpoints.USERS}/${uuid}`,
      [EUserType.SR]: `${BackendEndpoints.USERS}/travel-agents/${uuid}`,
    };
    const endpoint = endpointMapping[userType];
    if (userType === EUserType.ADMIN) {
      userData.notifications = notifications;
    }
    return this.client.patch(endpoint, toApiPayload(userData));
  };

  deleteUser = async (uuid: string): Promise<AxiosResponse> => {
    return this.client.delete(`${BackendEndpoints.USERS}/${uuid}`);
  };

  getHotelsAsHotelNames = async (): Promise<AxiosResponse<IHotelNamesResponse>> => {
    const params = {
      fields: {
        hotel: 'uuid,name,countryCode',
      },
      sort: 'hotel.name',
    };
    return this.client.get(BackendEndpoints.HOTEL, { params });
  };

  getOffersAsUuidAndName = async (uuids: string[]): Promise<AxiosResponse> => {
    const filter = uuids.map(uuid => `filter[offer][uuid:in][]=${uuid}`).join('&');
    const endpoint = `${BackendEndpoints.OFFERS}?fields[offer]=uuid,name&${filter}`;
    return this.client.get(endpoint);
  };

  getOffersForHotel = async (hotelUuid: string): Promise<IAPIRepsonse<IOffersOnHotelResponse, IApiErrorResponse>> => {
    const params = {
      filter: {
        offer: {
          hotelUuid,
        },
      },
      fields: {
        offer: 'uuid,name,order',
      },
      sort: 'offer.name',
    };
    return this.client.get(BackendEndpoints.OFFERS, { params }).then(response => ({
      response,
    }));
  };

  postOffersOrder = async (
    offersSortPayload: IOffersSortPayload
  ): Promise<IAPIRepsonse<IOffersOnHotelResponse, IApiErrorResponse>> => {
    return this.client
      .post(
        `${BackendEndpoints.OFFERS}/order`,
        toApiPayload<IOffersSortPayload>(offersSortPayload, { type: 'offersOrder' })
      )
      .then(response => ({
        response,
      }));
  };

  // used by some administration forms
  // these are NOT the hotel accommodations in the booking builder
  getAccommodationProductsForHotel = async (
    hotelUuid: string
  ): Promise<IAPIRepsonse<IAccommodationProductForHotelItem, IApiErrorResponse>> => {
    const params = {
      fields: {
        product: 'uuid,name,options,type',
      },
      filter: {
        product: {
          type: 'Accommodation',
          ownerUuid: hotelUuid,
        },
      },
    };
    return this.client.get(BackendEndpoints.PRODUCTS, { params }).then(response => ({
      response,
    }));
  };

  getHotelAccommodationProducts = async (
    hotelUuid: string,
    startDate: string,
    endDate: string,
    guestAges: any,
    clientCountryCode?: TCountryCode
  ): Promise<AxiosResponse> => {
    let endpoint = `/hotel-accommodation-products/${hotelUuid}`;
    if (clientCountryCode) {
      endpoint += `?clientCountryCode=${clientCountryCode}`;
    }

    return this.client.post(endpoint, {
      startDate,
      endDate,
      ageSets: guestAges,
    });
  };

  getProductsAsUuidAndName = async (uuids: string[]): Promise<AxiosResponse> => {
    const filter = uuids.map(uuid => `filter[product][uuid:in][]=${uuid}`).join('&');
    const endpoint = `${BackendEndpoints.PRODUCTS}?fields[product]=uuid,name&${filter}`;
    return this.client.get(endpoint);
  };

  getOffersList = async (query): Promise<AxiosResponse<IOffersListResponse>> => {
    return this.client.get(BackendEndpoints.OFFERS, { params: query });
  };

  deleteOffers = async (uuids): Promise<AxiosResponse<IOffersDeleteResponse> | undefined> => {
    if (uuids.length <= 0) {
      return;
    }

    let query = '?';
    query += uuids.map(uuid => `filter[offer][uuid:in][]=${uuid}`).join('&');

    const endpoint = `${BackendEndpoints.OFFERS}${query}`;
    return this.client.delete(endpoint);
  };

  getOffer = async (uuid: string): Promise<AxiosResponse<IOfferResponse>> => {
    // TODO: This really should take a params object, but this API makesa it really difficult to type properly.
    const endpoint = `${BackendEndpoints.OFFERS}/${uuid}?associations=hotel&fields[hotel]=name&fields[hotel]=countryCode`;
    return this.client.get(endpoint);
  };

  getBootstrapCountries = async (): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COUNTRIES}`;
    return this.client.get(endpoint);
  };

  getBootstrapExtraPersonSupplementProduct = async (): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.PRODUCTS}/extra-person`;
    return this.client.get(endpoint);
  };

  putOffer = async (offer: IOfferAPI): Promise<IAPIRepsonse<IOfferAPI, IApiErrorResponse>> => {
    return this.client
      .put(
        `${BackendEndpoints.OFFERS}/${offer.uuid}`,
        transformPut<IOfferAPI>(offer, 'offer', ['hotel'])
      )
      .then(response => ({
        response,
      }));
  };

  postOffer = async (offer: IOfferAPI): Promise<IAPIRepsonse<IOfferAPI, IApiErrorResponse>> => {
    return this.client
      .post(
        `${BackendEndpoints.OFFERS}?associations=hotel&fields[hotel]=name`,
        transformPost<IOfferAPI>(offer, 'offer', ['uuid', 'hotel'])
      )
      .then(response => ({
        response,
      }));
  };

  importRates = async (): Promise<AxiosResponse<IImportResponse | ErrorResponse>> => {
    return this.client.post(`${BackendEndpoints.RATES_LOADER}/import`);
  };

  getRatesImportStatus = async (): Promise<AxiosResponse<IImportResponse | ErrorResponse>> => {
    return this.client.get(`${BackendEndpoints.RATES_LOADER}/status`);
  };

  importProducts = async (): Promise<AxiosResponse<IImportResponse | ErrorResponse>> => {
    return this.client.post(`${BackendEndpoints.PRODUCTS_LOADER}/import`);
  };

  getProductsImportStatus = async (): Promise<AxiosResponse<IImportResponse | ErrorResponse>> => {
    return this.client.get(`${BackendEndpoints.PRODUCTS_LOADER}/status`);
  };

  importStaticRatesAvailability = async (): Promise<AxiosResponse<IImportResponse | ErrorResponse>> => {
    return this.client.post(`${BackendEndpoints.STATIC_RATES_AVAILABILITY}/loader/import`);
  };

  getStaticRatesAvailabilityImportStatus = async (): Promise<AxiosResponse<IImportResponse | ErrorResponse>> => {
    return this.client.get(`${BackendEndpoints.STATIC_RATES_AVAILABILITY}/loader/status`);
  };

  sanitizQueryObject = (query: ISearchQuery): ISearchQuery => {
    // Convery any strings that should be integers to integers
    // qs seem to not handle stings containing '+' correctly
    const sanitizeStarRatings = (sr: string[]) =>
      sr.map((rating: string) => (rating === '5 ' ? StarRating.FiveStarPlus : (rating as StarRating)));
    const sanitizeAges = (ages: string[]) => ages.map(age => parseInt(age, 10));
    const sanitizeNumberOfAdults = (s: string) => parseInt(s, 10);
    const sanitizePricePrange = (pr: PriceRange) => ({
      min: pr.min ? parseInt((<unknown>pr.min) as string, 10) : undefined,
      max: pr.max ? parseInt((<unknown>pr.max) as string, 10) : undefined,
    });

    const sanitizeBooleanProp = (obj: Record<string, unknown>, prop: string) => {
      if (!obj) return;

      const val = obj[prop];
      if (val === 'true' || val === true) {
        obj[prop] = true;
      } else {
        delete obj[prop];
      }
    };

    const sanitizeLodging = (x: Lodging): Lodging => {
      const y = {
        ...x,
        agesOfAllChildren: sanitizeAges(((<unknown>x.agesOfAllChildren) as string[] | undefined) || []),
        numberOfAdults: sanitizeNumberOfAdults((<unknown>x.numberOfAdults) as string),
        repeatCustomer: <unknown>x.repeatCustomer === 'true',
      };

      ['honeymoon', 'birthday', 'anniversary', 'wedding'].forEach(prop => sanitizeBooleanProp(y, prop));

      return y;
    };

    return {
      ...query,
      startDate: query.startDate.split('T')[0],
      endDate: query.endDate.split('T')[0],
      name: query.name === ALL_COUNTRIES_AND_RESORTS ? '' : query.name,
      priceRange: query.priceRange ? sanitizePricePrange(query.priceRange) : { min: undefined, max: undefined },
      mealPlanCategories: without(['Any' as MealPlanNames], query.mealPlanCategories || []),
      starRatings: query.starRatings ? sanitizeStarRatings([...query.starRatings]) : [],
      lodgings: query.lodgings.map(x => sanitizeLodging(x)),
      clientCountryCode: query.clientCountryCode || null,
    };
  };

  createBookingProcess = async (
    bookingAttributes: IBookingAttributes,
    newProposalAttributes: NewProposalPayload | null,
    clientCountryCode?: TCountryCode,
    selectedCompanyMembership: ICompanyMembership | null = null
  ): Promise<AxiosResponse<ICreateBookingProcessResponse>> => {
    let endpoint = `${BackendEndpoints.BOOKINGS}/process`;
    if (clientCountryCode) {
      endpoint += `?clientCountryCode=${clientCountryCode}`;
    }

    // @see https://pureescapes.atlassian.net/browse/OWA-4131?focusedCommentId=27389
    // these `bookingAttributes` can come from multiple places, so we need to handle this
    // at the last step.
    if (bookingAttributes.bookingInformation.taMarginAmount === '') {
      delete bookingAttributes.bookingInformation.taMarginAmount;
    }

    const payload: ICreateBookingRequest = {
      data: {
        attributes: {
          ...omitDeep(bookingAttributes, ['availableToInstantBook']),
          // if we have a selected company membership, add the uuid in. otherwise, the uuid is null
          ...(selectedCompanyMembership
            ? { companyMembershipUuid: selectedCompanyMembership.uuid }
            : { companyMembershipUuid: null }),
        },
      },
    };

    if (newProposalAttributes) {
      payload.data.proposalInfo = {
        shouldCreateNew: true,
        attributes: newProposalAttributes,
      };
    }

    return this.client.post(endpoint, payload);
  };

  getCreateBookingProcess = async (uuid: string): Promise<AxiosResponse<ICreateBookingProcessResponse>> => {
    const endpoint = `${BackendEndpoints.BOOKINGS}/process/${uuid}`;
    return this.client.get(endpoint);
  };

  createBooking = async (
    bookingAttributes: IBookingAttributes,
    newProposalAttributes: NewProposalPayload | null,
    clientCountryCode?: TCountryCode,
    selectedCompanyMembership: ICompanyMembership | null = null
  ): Promise<{ uuid: string }> => {
    let res = await this.createBookingProcess(
      bookingAttributes,
      newProposalAttributes,
      clientCountryCode,
      selectedCompanyMembership
    );

    while (res.data.data.status !== EGenericStatusValue.DONE) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      res = await this.getCreateBookingProcess(res.data.data.uuid);
    }

    if (res.data.data.data?.success) {
      return res.data.data.data.booking!;
    }

    throw new Error(res.data.data.data!.error);
  };

  liveRatesPriceCheck = async (
    hotelUuid: string,
    priceCheckReq: IPriceCheckReq,
    clientCountryCode?: TCountryCode | null
  ): Promise<AxiosResponse<BookingBuilderResponse | ErrorResponse>> => {
    let endpoint = `${BackendEndpoints.LIVE_RATES}/${hotelUuid}/price-check`;
    if (clientCountryCode) {
      endpoint += `?clientCountryCode=${clientCountryCode}`;
    }
    return this.client.post(endpoint, priceCheckReq);
  };

  addBookingToProposal = async (proposalUuid: string, bookingUuid: string): Promise<AxiosResponse> => {
    const endpoint = `proposals/${proposalUuid}/bookings/${bookingUuid}`;

    return this.client.post(endpoint);
  };

  fetchCompanyLogo = async (companyUuid: string): Promise<AxiosResponse<ICompanyLogoResponse>> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}/logo`;
    return this.client.get(endpoint);
  };

  removeCompanyLogo = async (companyUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}/logo`;
    return this.client.delete(endpoint);
  };

  fetchMainCompanyInfo = async (): Promise<AxiosResponse<ICompanyLogoResponse>> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/main-company/info`;
    return this.client.get(endpoint);
  };

  setCompanyLogoPosition = async (companyUuid: string, logoPosition: ICompanyLogoPosition): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}/logoPosition`;
    return this.client.post(endpoint, { logoPosition });
  };

  uploadFile = async (formData: FormData): Promise<AxiosResponse<IUploadResponse>> => {
    return this.client.post('upload', formData);
  };

  deleteUpload = async (uuid: string): Promise<AxiosResponse> => {
    return this.client.delete(`upload/${uuid}`);
  };

  getUploadsForBooking = async (bookingUuid: string, tag?: string): Promise<AxiosResponse<IUploadListResponse>> => {
    const defaultOptions = {
      'filter[upload][ownerUuid]': bookingUuid,
      'page[limit]': 100,
      sort: '-upload.createdAt',
    };

    const options = tag ? { ...defaultOptions, 'filter[upload][tag]': tag } : defaultOptions;
    const query = buildQuery(options);

    return this.client.get(`${BackendEndpoints.UPLOAD}?${query}`);
  };

  getProposal = async (uuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.PROPOSALS}/${uuid}`;
    return this.client.get(endpoint, {
      params: {
        associations: ['uploads', 'bookings'],
      },
    });
  };

  previewProposal = async (
    uuid: string,
    previewParams: IPreviewParams
  ): Promise<AxiosResponse<IProposalPreviewResponse>> => {
    const endpoint = `${BackendEndpoints.PROPOSALS}/${uuid}/preview`;
    return this.client.post(endpoint, {
      data: {
        attributesToRewrite: previewParams.attributesToRewrite,
        shouldUseCompanyLogo: previewParams.shouldUseCompanyLogo,
      },
    });
  };

  getCompanyLogo = async (uuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${uuid}/logo`;
    return this.client.get(endpoint);
  };

  getAssignedSalesRepresentatives = async (
    travelAgentUuid: string
  ): Promise<AxiosResponse<ISalesRepresentativesResponse>> => {
    const endpoint = `${BackendEndpoints.USERS}/${travelAgentUuid}/assigned-sales-representatives`;
    return this.client.get(endpoint);
  };

  getLiveRates = async (
    hotelUuid: string,
    startDate: string,
    endDate: string,
    numberOfAdults: number,
    agesOfAllChildren: number[]
  ): Promise<AxiosResponse<LiveRatesResponse>> => {
    const params = {
      startDate,
      endDate,
      numberOfAdults,
      agesOfAllChildren,
    };
    const endpoint = `${BackendEndpoints.LIVE_RATES}/${hotelUuid}`;
    return this.client.get(endpoint, { params });
  };

  deleteStaysMappings = async (hotelUuid: string): Promise<AxiosResponse<LiveRatesResponse>> => {
    const endpoint = `${BackendEndpoints.LIVE_RATES}/stays/${hotelUuid}`;

    return this.client.delete(endpoint);
  };

  postRateBreak = async (options: IPostRateBreakRequest): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.RATE_BREAK}`;

    return this.client.post(endpoint, {
      data: {
        type: 'rateBreak',
        attributes: {
          ...options,
        },
      },
    });
  };

  patchRateBreak = async (uuid: string, options: IPostRateBreakRequest): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.RATE_BREAK}/${uuid}`;

    return this.client.patch(endpoint, {
      data: {
        type: 'rateBreak',
        id: uuid,
        attributes: {
          ...options,
        },
      },
    });
  };

  getRateBreakList = async (query): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.RATE_BREAK}`;
    return this.client.get(endpoint, { params: { ...query } });
  };

  deleteRateBreakList = async (query): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.RATE_BREAK}`;
    return this.client.delete(endpoint, { params: { ...query } });
  };

  getRateBreak = async (uuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.RATE_BREAK}/${uuid}`;
    return this.client.get(endpoint);
  };

  fetchCompanyHotelMarkups = async (
    companyUuid: string,
    sortField?: string,
    sortOrder?: string
  ): Promise<AxiosResponse> => {
    const endpointBuilderArray: string[] = [`${BackendEndpoints.COMPANY_HOTEL_MARKUPS}/${companyUuid}`];
    if (sortField !== undefined) {
      endpointBuilderArray.push(sortField);
    }
    if (sortOrder !== undefined) {
      endpointBuilderArray.push(sortOrder);
    }
    const endpoint = endpointBuilderArray.join('/');
    return this.client.get(endpoint);
  };

  fetchCompanyHotelAvailableToCreateMarkups = async (companyUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_HOTEL_MARKUPS}/${companyUuid}/available-hotels`;
    return this.client.get(endpoint);
  };

  postCompanyHotelMarkups = async (
    companyUuid: string,
    markupPercentage: number,
    hotelUuids: string[]
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_HOTEL_MARKUPS}/${companyUuid}`;
    return this.client.post(endpoint, { markupPercentage, hotelUuids });
  };

  putCompanyHotelMarkups = async (
    companyUuid: string,
    markupPercentage: number,
    hotelUuids: string[]
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_HOTEL_MARKUPS}/${companyUuid}`;
    return this.client.put(endpoint, { markupPercentage, hotelUuids });
  };

  deleteCompanyHotelMarkups = async (companyUuid: string, hotelUuids: string[]): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_HOTEL_MARKUPS}/${companyUuid}/delete`;
    return this.client.post(endpoint, { hotelUuids });
  };

  fetchProductsWithCompanyHotelMarkup = async (): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_HOTEL_MARKUPS}/product-types-with-markup`;
    return this.client.get(endpoint);
  };

  getCompanyInvoiceAddressee = async (
    companyUuid: string
  ): Promise<AxiosResponse<ICompanyInvoiceAddresseeListResponse>> => {
    const params = {
      filter: {
        invoiceAddressee: {
          ownerUuid: companyUuid,
          ownerType: 'company',
        },
      },
      page: {
        limit: 1,
      },
    };

    const endpoint = `${BackendEndpoints.INVOICE_ADDRESSEES}`;
    return this.client.get(endpoint, { params });
  };

  upsertCompanyInvoiceAddressee = async (
    companyUuid: string,
    addressee: IInvoiceAddressee
  ): Promise<AxiosResponse<ICompanyInvoiceAddresseeResponse>> => {
    const root = BackendEndpoints.INVOICE_ADDRESSEES;
    const payload = {
      data: {
        type: 'invoiceAddressee',
        attributes: {
          ...addressee,
          ownerType: 'company',
          ownerUuid: companyUuid,
        },
      },
    };

    if (!addressee.uuid) {
      return this.client.post(root, payload);
    }

    // @ts-ignore
    payload.data.id = addressee.uuid;
    return this.client.patch(`${root}/${addressee.uuid}`, payload);
  };

  getAggregateLiveRates = async (
    hotelUuid: string,
    startDate: string,
    endDate: string,
    guestAges: IGuestAgesItem[],
    clientCountryCode?: TCountryCode
  ): Promise<AxiosResponse<ICompanyInvoiceAddresseeResponse>> => {
    let endpoint = `${BackendEndpoints.LIVE_RATES}/${hotelUuid}/aggregate`;
    if (clientCountryCode) {
      endpoint += `?clientCountryCode=${clientCountryCode}`;
    }
    const payload = {
      startDate,
      endDate,
      guestAges,
    };

    const response = await this.client.post(endpoint, payload);
    return response;
  };

  getHotelListData = async (companyUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_HOTEL_DISABLE}/manage/${companyUuid}`;
    return this.client.get(endpoint);
  };

  postHotelListData = async (companyUuid: string, hotelUuids: string[]): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_HOTEL_DISABLE}/manage/${companyUuid}`;
    return this.client.post(endpoint, { hotels: hotelUuids });
  };

  getCompanyDocumentsData = async (
    companyUuid: string,
    sortField?: CompanyDocumentsSortField,
    sortOrder?: CompanyDocumentsSortOrder
  ): Promise<AxiosResponse> => {
    let endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}/documents`;
    if (sortField) {
      endpoint = endpoint.concat(`?sortField=${sortField}`);
      if (sortOrder) {
        endpoint = endpoint.concat(`&sortDirection=${sortOrder}`);
      }
    }

    return this.client.get(endpoint);
  };

  postCompanyDocument = async (companyUuid: string, formData: FormData): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}/document`;
    return this.client.post(endpoint, formData);
  };

  deleteCompanyDocument = async (companyUuid: string, uploadUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}/document/${uploadUuid}`;
    return this.client.delete(endpoint);
  };

  postCompanyManagers = async (
    companyUuid: string,
    bookingManagers: string[],
    taManagers: string[]
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANIES}/${companyUuid}/managers`;
    return this.client.post(endpoint, { bookingManagers, taManagers });
  };

  getStaticRatesAvailabilityData = async (
    accommodationUuids: string[],
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.STATIC_RATES_AVAILABILITY}/get-range-data`;

    return this.client.post(endpoint, {
      accommodationUuids,
      startDate,
      endDate,
    });
  };

  getCredentialsInfo = async (params: IGetCredentialsInfo): Promise<AxiosResponse<IGetCredentialsInfoResponse>> => {
    const endpoint = `${BackendEndpoints.USERS}/credentials`;
    return this.client.get(endpoint, { params });
  };

  postCredentialsInfo = async (payload: IPostCredentialsInfo): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/credentials`;
    return this.client.post(endpoint, payload);
  };

  getTravelAgentViaUuid = async (
    tauuid: string,
    associations?: string[],
    userType: EUserType = EUserType.ADMIN
  ): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const endpointMapping = {
      [EUserType.ADMIN]: `${BackendEndpoints.USERS}/${tauuid}`,
      [EUserType.SR]: `${BackendEndpoints.USERS}/travel-agents/${tauuid}`,
    };
    const endpoint = endpointMapping[userType];
    return this.client.get(endpoint, {
      params: {
        associations,
      },
    });
  };

  getBookingComRates = async (
    hotelUuid: string,
    startDate: string,
    endDate: string,
    guestAges: IGuestAgesItem[],
    clientCountryCode?: string
  ): Promise<AxiosResponse> => {
    let endpoint = `${BackendEndpoints.BOOKING_COM_RATES}/${hotelUuid}`;

    const roomCount = guestAges.length;
    const adultCount = guestAges.reduce((count, cv) => {
      return (count += cv.numberOfAdults);
    }, 0);
    const childAges = guestAges.reduce((ages, cv) => {
      if (cv.agesOfAllChildren) {
        return ages.concat(cv.agesOfAllChildren);
      }
      return ages;
    }, [] as number[]);

    const payload = {
      startDate,
      endDate,
      roomCount,
      adultCount,
      childAges,
    };
    if (clientCountryCode) {
      endpoint += `?clientCountryCode=${clientCountryCode}`;
    }
    return this.client.post(endpoint, payload);
  };

  updateUserAssignments = async (
    userUuid: string,
    assignmentUuids: string[],
    isUpdate?: boolean,
    isUnAssign?: boolean
  ): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const endpoint = `${BackendEndpoints.USERS}/${userUuid}/assignments`;
    const data = {
      data: assignmentUuids,
      isUpdate,
      isUnAssign,
    };
    return this.client.put(endpoint, data);
  };

  getSRs = async (): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const params = {
      filter: {
        user: {
          type: EUserType.SR,
        },
      },
    };
    return this.client.get(BackendEndpoints.USERS, { params });
  };

  getUsersByEmail = async (email: string): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const params = {
      filter: { user: { email } },
    };

    return this.client.get(BackendEndpoints.USERS, { params });
  };

  getUserByUuid = async (uuid: string): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const params = {
      filter: { user: { uuid } },
    };

    return this.client.get(BackendEndpoints.USERS, { params });
  };

  getPossibleNotificationsForRole = async (role: EUserType): Promise<AxiosResponse<IPossibleNotificationsResponse>> => {
    return this.client.get(`${BackendEndpoints.NOTIFICATIONS}/role/${role}`);
  };

  getEnabledNotificationsForUser = async (userUuid: string): Promise<AxiosResponse<IEnabledNotificationsResponse>> => {
    return this.client.get(`${BackendEndpoints.NOTIFICATIONS}/user/${userUuid}`);
  };

  setNotificationsForAllCompanyUsers = async (
    companyUuid: string,
    role: EUserType,
    notificationCodes: string[],
    enabled: boolean
  ): Promise<AxiosResponse> => {
    const payload = {
      notificationCodes,
      enabled,
    };
    return this.client.post(`${BackendEndpoints.NOTIFICATIONS}/company/${companyUuid}/role/${role}`, payload);
  };

  getSalesReps = async (): Promise<AxiosResponse<ITravelAgentResponse>> => {
    const params = {
      filter: {
        user: {
          type: 'sr',
        },
      },
    };
    return this.client.get(BackendEndpoints.USERS, { params });
  };

  getInternalUserViaUuid = async (uuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.USERS}/${uuid}`;
    return this.client.get(endpoint, {
      params: {
        associations: ['assignedTravelAgents'],
      },
    });
  };

  updateInternalUser = async (
    uuid: string,
    userData: Partial<IInternalUser>,
    notifications: IEnabledNotification[]
  ): Promise<AxiosResponse> => {
    return this.client.patch(`${BackendEndpoints.USERS}/${uuid}`, toApiPayload(userData));
  };

  fetchCompanyDepartments = async (companyUuid: string): Promise<AxiosResponse> => {
    return this.client.get(BackendEndpoints.COMPANY_DEPARTMENTS, {
      params: {
        filter: {
          companyDepartment: {
            companyUuid,
          },
        },
      },
    });
  };

  postCompanyDepartment = async (
    companyUuid: string,
    departmentName: string,
    responsibleName: string,
    emails: string
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_DEPARTMENTS}`;
    return this.client.post(
      endpoint,
      toApiPayload(
        {
          companyUuid,
          name: departmentName,
          responsibleName: responsibleName || null,
          emails,
        },
        { type: 'companyDepartment' }
      )
    );
  };

  updateCompanyDepartment = async (
    departmentUuid: string,
    companyUuid: string,
    departmentName: string,
    responsibleName: string,
    emails: string
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_DEPARTMENTS}/${departmentUuid}`;
    return this.client.patch(
      endpoint,
      toApiPayload(
        {
          name: departmentName,
          responsibleName: responsibleName || null,
          emails,
        },
        { type: 'companyDepartment', id: departmentUuid }
      )
    );
  };

  deleteCompanyDepartment = async (departmentUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_DEPARTMENTS}/${departmentUuid}`;
    return this.client.delete(endpoint);
  };

  postCompanyMembership = async (
    companyUuid: string,
    name: string,
    status: boolean,
    logoUrl?: string,
    contactEmail?: string,
    contactPhone?: string,
    contactInfo?: string,
    benefitsDescription?: string,
    companyDepartmentUuid?: string
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_MEMBERSHIPS}`;
    return this.client.post(
      endpoint,
      toApiPayload(
        {
          companyUuid,
          name,
          status,
          logoUrl: logoUrl === '' ? undefined : logoUrl,
          contactEmail: contactEmail === '' ? undefined : contactEmail,
          contactPhone: contactPhone === '' ? undefined : contactPhone,
          contactInfo: contactInfo === '' ? undefined : contactInfo,
          benefitsDescription: benefitsDescription === '' ? undefined : benefitsDescription,
          companyDepartmentUuid: companyDepartmentUuid || null,
        },
        { type: 'companyMembership' }
      )
    );
  };

  patchCompanyMembership = async (
    companyUuid: string,
    uuid: string,
    name: string,
    status: boolean,
    logoUrl?: string | null,
    contactEmail?: string,
    contactPhone?: string,
    contactInfo?: string,
    benefitsDescription?: string,
    companyDepartmentUuid?: string
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_MEMBERSHIPS}/${uuid}`;
    return this.client.patch(
      endpoint,
      toApiPayload(
        {
          companyUuid,
          name,
          status,
          logoUrl: logoUrl === '' ? undefined : logoUrl,
          contactEmail: contactEmail === '' ? undefined : contactEmail,
          contactPhone: contactPhone === '' ? undefined : contactPhone,
          contactInfo: contactInfo === '' ? undefined : contactInfo,
          benefitsDescription: benefitsDescription === '' ? undefined : benefitsDescription,
          companyDepartmentUuid: companyDepartmentUuid || null,
        },
        { type: 'companyMembership', id: uuid }
      )
    );
  };

  deleteCompanyMembership = async (membershipUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_MEMBERSHIPS}/${membershipUuid}`;
    return this.client.delete(endpoint);
  };

  fetchCompanyMemberships = async (companyUuid: string, params?: any): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.COMPANY_MEMBERSHIPS}`;
    return this.client.get(endpoint, {
      params: {
        sort: 'companyMembership.name',
        filter: {
          companyMembership: {
            companyUuid,
          },
        },
        associations: ['companyDepartment', 'company'],
        // Commented out until there is a custom backend endpoint
        // associations: ['companyDepartment', 'company', 'bookings'],
        ...(params ? params : {}),
      },
    });
  };

  getUploadsForCompanyMembership = async (uuid: string, url: string): Promise<AxiosResponse<IUploadListResponse>> => {
    return this.client.get(BackendEndpoints.UPLOAD, {
      params: {
        filter: {
          upload: {
            url,
            tag: 'CompanyMembershipLogo',
            ownerUuid: uuid,
          },
        },
      },
    });
  };

  doesEmailExist = async (email: string): Promise<AxiosResponse<ITravelAgentResponse>> => {
    return this.client.post(`${BackendEndpoints.USERS}/emailok`, {
      email,
    });
  };

  getLiveRateMappings = async (filter: ILiveRatesListingFilter, source): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.LIVE_RATES}/stays`;
    const promise = this.client.post(
      endpoint,
      {
        nameFilter: filter.nameFilter,
        externalSystem: filter.externalSystem,
        limit: filter.page_size,
        offset: filter.page_size * (filter.page - 1),
        sortField: filter.order_by,
        sortDirection: filter.order_type,
      },
      {
        cancelToken: source.token,
      }
    );
    promise[CANCEL] = () => source.cancel();

    return promise;
  };

  saveLiveRateMappingsList = async (
    internalRoomsList: ILiveRatesSettingsInternalRoomsItem[]
  ): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.PRODUCTS}`;
    return this.client.patch(endpoint, {
      data: internalRoomsList.map(ir => {
        return {
          type: 'product',
          id: ir.uuid,
          attributes: {
            externalProductId: ir.externalId === '' ? null : ir.externalId,
          },
        };
      }),
    });
  };

  getLiveRateStayRooms = async (stayUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.LIVE_RATES}/stays/${stayUuid}/rooms`;
    return this.client.get(endpoint);
  };

  getAccommodationProductsForHotelForLiveRateMappings = async (hotelUuid: string): Promise<AxiosResponse> => {
    const params = {
      filter: {
        product: {
          type: 'Accommodation',
          ownerUuid: hotelUuid,
        },
      },
    };
    return this.client.get(BackendEndpoints.PRODUCTS, { params });
  };

  getLiveRateMappableStays = async (): Promise<AxiosResponse> => {
    const endpoint = `${BackendEndpoints.LIVE_RATES}/mappable-stays`;
    const promise = this.client.get(endpoint);
    return promise;
  };

  callWelcome = async (tokens: ITokenSet): Promise<AxiosResponse> => {
    const config = {
      headers: {
        // setting lower case so we are alligned with the rest of calls in FE code
        Authorization: `Bearer ${tokens.accessToken}`,
        IdToken: `${tokens.idToken}`,
      },
    };
    return this.client.post(`${BackendEndpoints.USERS}/welcome`, {}, config);
  };

  searchUsersSimple = async (filterTerm: string, sort: string, page?: IPage): Promise<AxiosResponse> => {
    return this.client.post(`${BackendEndpoints.USERS_SEARCH}/simple`, {
      filter: {
        term: filterTerm,
      },
      page,
      sort,
    });
  };

  addToBasket = async (
    bookingBuilderRequest: BookingBuilderRequest,
    basketBuild?: IBasketBuildL4
  ): Promise<AxiosResponse> => {
    let endpoint = `${BackendEndpoints.BASKET}`;

    const body: {
      buildRequest: BookingBuilderRequest;
      basketBuildUuid?: string;
    } = {
      buildRequest: bookingBuilderRequest,
    };

    if (basketBuild) {
      body.basketBuildUuid = basketBuild.uuid;
    }
    return this.client.post(endpoint, body);
  };

  getBasket = async (params: any): Promise<AxiosResponse<IGetBasketResponse>> => {
    const endpoint = `${BackendEndpoints.BASKET}`;
    return this.client.get(endpoint, {
      params: {
        ...params,
      },
    });
  };

  getPhotosForHotels = async (hotelUuids: string[]): Promise<AxiosResponse<IUploadListResponse>> => {
    const options = {
      'page[limit]': 100,
      sort: '-upload.createdAt',
    };

    let query = buildQuery(options);

    hotelUuids.forEach(hotelUuid => {
      query += `&filter[upload][ownerUuid:in][]=${hotelUuid}`;
    });

    ['photo', 'featuredPhoto'].forEach(tag => {
      query += `&filter[upload][tag:in][]=${tag}`;
    });

    return this.client.get(`${BackendEndpoints.UPLOAD}?${query}`);
  };

  getBasketUsers = async (): Promise<AxiosResponse<IBasketUserResponseItem[]>> => {
    const endpoint = `${BackendEndpoints.BASKET}/users`;
    return this.client.get(endpoint);
  };

  removeFromBasket = async (
    basketBuildUuid: string,
    actingOnBehalOfUserUuid: string | null | undefined
  ): Promise<AxiosResponse> => {
    const query = {
      actingOnBehalOfUserUuid,
    };
    let endpoint = `${BackendEndpoints.BASKET}`;

    return this.client.delete(`${endpoint}/${basketBuildUuid}`, { params: query });
  };
}

export const makeBackendApiWithoutHeaders = (): BackendApiService<AxiosWrapper<AxiosInstance>> => {
  const dynamicParameters = ParameterService.getParameters();

  const client = axios.create({
    baseURL: dynamicParameters.BACKEND_BASE_URL,
  });

  const clientWrapper = new AxiosWrapper(client);

  return new BackendApiService<AxiosWrapper<AxiosInstance>>(clientWrapper);
};

export const makeBackendApi = (travelAgentUuid?: string): BackendApiService<AxiosWrapper<AxiosInstance>> => {
  const dynamicParameters = ParameterService.getParameters();
  const headers: any = {};

  const client = axios.create({
    baseURL: dynamicParameters.BACKEND_BASE_URL,
    headers: injectJwtTokenIntoHeaders(headers),
    withCredentials: true,
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });

  client.defaults.params = {
    travelAgentUuid: travelAgentUuid,
  };

  const clientWrapper = new AxiosWrapper(client);

  return new BackendApiService<AxiosWrapper<AxiosInstance>>(clientWrapper);
};
