import { createSelector } from 'reselect';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { ITCBookingStatementDomain } from './model';

export const tcBookingStatementDomain = (state: any): ITCBookingStatementDomain => {
  return state.tcBookingStatement;
};

export const tcBookingStatementTotalsSelector = createSelector(
  tcBookingStatementDomain, 
  domain => domain.tcBookingTotals
);

export const tcBookingStatementSelector = createSelector(
  tcBookingStatementDomain, 
  domain => domain.bookingsByCurrency
);

export const tcStatementFilteDateRangeValueSelector = createSelector(
  tcBookingStatementDomain, 
  domain => domain.statementFilterDateRange
);

export const tcStatementFilterDateRangeTypeSelector = createSelector(
  tcBookingStatementDomain, 
  domain => domain.statementFilterDateRangeType
);

export const tcBookingStatementCompanySelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.selectedCompanyUuid
);

export const tcBookingStatementCurrencySelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.bookingsByCurrency.currency
);

export const tcBookingStatementSortSelector = createSelector(tcBookingStatementDomain, domain => ({
  field: domain.bookingsByCurrency.sortBy,
  order: domain.bookingsByCurrency.sortOrder,
}));

export const tcBookingStatementCsvExportLoading = createSelector(
  tcBookingStatementDomain,
  domain => domain.csvIsLoading
);

export const tcBookingStatementFilterSelector = createSelector(
  tcBookingStatementSelector,
  bookingsByCurrency => bookingsByCurrency.filter
);
export const tcStatementFilterStatusSelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.statementFilterStatus
);
export const tcStatementFilterBookingRefsSelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.statementFilterBookingRefs
);
export const tcStatementFilterAmountMinSelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.statementFilterAmountMin
);
export const tcStatementFilterAmountMaxSelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.statementFilterAmountMax
);

export const tcBookingStatementPageSelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.bookingsByCurrency.currentPage
);

export const tcBookingStatementItemsPerPageSelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.bookingsByCurrency.itemsPerPage
);

export const tcBookingStatementItemsPageCountSelector = createSelector(
  tcBookingStatementDomain,
  tcBookingStatementDomain => {
    const itemsPerPage = tcBookingStatementDomain.bookingsByCurrency.itemsPerPage;
    const totalResults = tcBookingStatementDomain.bookingsByCurrency.data?.pagination.totalRows;
    return itemsPerPage && totalResults ? Math.ceil(totalResults / itemsPerPage) : 0;
  }
);

export const exportCsvNamespaceSelector = createSelector(
  tcBookingStatementDomain,
  tcBookingStatementDomain => tcBookingStatementDomain.exportCsvNamespace
);

export const tableDataQuerySelector = createSelector(
  tcBookingStatementPageSelector,
  tcBookingStatementItemsPerPageSelector,
  tcBookingStatementSortSelector,
  tcBookingStatementFilterSelector,
  (pageNumber, itemsPerPage, sort, filtersData = {}) => {
    const filter: {
      humanReadableId?: string[];
      fromDate?: string;
      toDate?: string;
      dateRangeType?: string;
      status?: string[];
      amountMinCents?: number;
      amountMaxCents?: number;
    } = {
      humanReadableId: filtersData.humanReadableId,
      fromDate: filtersData.fromDate,
      toDate: filtersData.toDate,
      status: filtersData.status,
      dateRangeType: filtersData.dateRangeType,
      amountMinCents: filtersData.amountMinCents,
      amountMaxCents: filtersData.amountMaxCents,
    };

    const query = {
      filter,
      sort: {
        field: sort.field,
        order: sort.order,
      },
      pagination: {
        offset: pageNumber * itemsPerPage,
        limit: itemsPerPage,
      },
    };

    return query;
  }
);

export const statementPdfSelector = createSelector(tcBookingStatementDomain, domain => domain.statementPdf);

export const statementPdfGenerationInProgress = createSelector(
  statementPdfSelector,
  pdf => pdf.requests.generate === ENetworkRequestStatus.PENDING
);

export const statementDownloadHistoryInProgress = createSelector(
  statementPdfSelector,
  pdf => pdf.requests.downloadHistory === ENetworkRequestStatus.PENDING
);

export const tcBookingStatementRequestSelector = createSelector(
  tcBookingStatementDomain,
  domain => domain.bookingsByCurrency.request
);

export const statementDownloadedPdfs = createSelector(statementPdfSelector, pdf => pdf.downloadedPdfs);
