import { createSelector } from 'reselect';
import { INewUpload, ITaskManagementDomain } from './model';
import { ENetworkRequestStatus, IUser, Upload } from 'services/BackendApi';
import { ESortOrder } from 'store/common/types';
import { ETasksSortableKey, ITask, ITaskCategory, ITaskComment, ITaskReminder, ITaskTeam } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { UPLOAD_FILE_SIZE_LIMIT } from 'config';
import { INewUploadPayload } from '../bookingManager/subdomains/uploads/model';


export const taskManagementDomain = (state: any): ITaskManagementDomain => state.taskManagement;

export const idFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.filters.id
);

export const categoriesFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string[] => domain.filters.categories
);

export const statusesFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string[] => domain.filters.statuses
);

export const teamFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string | null => domain.filters.team
);

export const assigneeFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string | null => domain.filters.assignee
);

export const priorityFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string | null => domain.filters.priority
);

export const datesFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string[] => domain.filters.dates
);

export const bookingRefFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.filters.bookingRef
);

export const guestNameFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.filters.guestName
);

export const reporterFilterSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.filters.reporter
);

export const getCategoriesRequestSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.requests.getCategories
);

export const getTeamsRequestSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.requests.getTeams
);

export const getAssigneesRequestSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.requests.getAssignees
);

export const getReportersRequestSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.requests.getReporters
);

export const categoriesSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): any[] => domain.data.categories
);

export const teamsSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): any[] => domain.data.teams
);

export const getFilterInfoRequestSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string => domain.requests.getFilterInfo
);

export const getTaskCategoriesSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ITaskCategory[] => domain.data.categories
);

export const getTaskTeamsSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ITaskTeam[] => domain.data.teams
);

export const getTaskAssigneesSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): Pick<IUser, 'uuid' | 'firstName' | 'lastName'>[] => domain.data.assignees
);

export const getTaskReportersSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): Pick<IUser, 'uuid' | 'firstName' | 'lastName'>[] => domain.data.reporters
);

export const getTasksSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ITask[] => domain.data.results
);

export const getTasksCountSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): number | null => domain.pagination.totalItems
);

export const tasksPageCountSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): number => domain.pagination.totalItems ? Math.ceil(domain.pagination.totalItems / domain.pagination.itemsPerPage) : 0
);

export const currentPageSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): number => domain.pagination.currentPage
);

export const taskSortBySelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ETasksSortableKey => domain.sort.sortBy
);

export const taskSortOrderSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ESortOrder => domain.sort.sortOrder
);

export const taskitemsPerPageSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): number => domain.pagination.itemsPerPage
);

export const tasksLoadingSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): boolean => domain.data.tableLoading
);

export const createTaskSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ENetworkRequestStatus => domain.requests.createTask
);

export const editTaskSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ENetworkRequestStatus => domain.requests.editTask
);

export const getTaskLoadingSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ENetworkRequestStatus => domain.requests.getTask
);

export const getListCommentsLoadingSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ENetworkRequestStatus => domain.requests.listComments
);

export const selectedTaskSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ITask | null => domain.data.selectedTask
);

export const selectedCommentIdSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): string | null => domain.data.selectedCommentId
);

export const listTaskCommentsSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ITaskComment[] => domain.data.comments
);

export const focusedCommentsSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): (string | null)[] => domain.data.focusedComments
);

export const commentSavingSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ENetworkRequestStatus => domain.requests.createTaskComment
);

export const getdeleteCommentLoadingSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ENetworkRequestStatus => domain.requests.deleteComment
);

export const listTaskRemindersSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ITaskReminder[] => domain.data.reminders
);

export const newTaskAttachmentPayloadSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): INewUploadPayload | null => domain.data.attachments.newAttachment.payload
);

export const newTaskAttachmentLoadSelector = createSelector(
  taskManagementDomain,
  (domain: ITaskManagementDomain): ENetworkRequestStatus => domain.data.attachments.newAttachment.request
);

export const newTaskAttachmentFileValidationSelector = createSelector(newTaskAttachmentPayloadSelector, payload => {
  if (!payload) {
    return [];
  }

  if (!payload.file) {
    return ['Required'];
  }

  if (payload.file.size > 10 * 1024 * 1024) {
    return ['File exceeds size limit.'];
  }

  return [];
});

export const newTaskAttachmentDisplayNameValidationSelector = createSelector(newTaskAttachmentPayloadSelector, payload =>
  !payload || payload.displayName ? [] : ['Required']
);

export const newTaskAttachmentValidationSelector = createSelector(
  newTaskAttachmentFileValidationSelector,
  newTaskAttachmentDisplayNameValidationSelector,
  (file, displayName ) => ({ file, displayName })
);

