import { call, takeLatest, select, put, all } from 'redux-saga/effects';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { BackendApiService, IGuestAgesItem, ISearchQuery, makeBackendApi } from 'services/BackendApi';
import { pathOr, pick } from 'ramda';
import { uniqBy } from 'lodash';
import { formatDate } from 'utils';
import addDays from 'date-fns/addDays';
import * as Sentry from '@sentry/browser';

import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as HotelAccommodationProductActions from './actions';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import { TCountryCode } from 'interfaces';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { IHAPStaticRateResponseObject } from './types';

type GetRatesResponse = {
  static: IHAPStaticRateResponseObject[];
  live: any;
  bookingCom: any;
}

const getRates = async (
  backendApi: BackendApiService<AxiosWrapper<AxiosInstance>>,
  isSr: boolean,
  hotelUuid: string,
  startDate: string,
  endDate: string,
  guestAges: IGuestAgesItem[],
  clientCountryCode?: string
): Promise<GetRatesResponse> => {
  const dynamicParameters = ParameterService.getParameters();

  const shouldCallBookingComEndpoint =
    (dynamicParameters.ENABLE_BOOKING_COM_RATES_IN_BBV1_FOR_SRS && isSr) ||
    dynamicParameters.ENABLE_BOOKING_COM_RATES_IN_BBV1;
  
  const getters = [
    backendApi.getHotelAccommodationProducts,
    backendApi.getAggregateLiveRates,
  ];

  if(shouldCallBookingComEndpoint) {
    getters.push(backendApi.getBookingComRates);
  }
  
  const ps = getters.map(async g => {
    try {
      const value = await g(
        hotelUuid,
        startDate,
        endDate,
        guestAges,
        clientCountryCode as TCountryCode
      );
      return { status: 'fulfilled', value };
    } catch(err) {
      Sentry.captureException(err);
      return { status: 'rejected', reason: err };
    }
  });

  const [stc, live, bookingCom] = await Promise.all(ps);
  if(stc.status === 'rejected') {
    throw stc.reason;
  }
  
  return {
    static: stc.value?.data,
    live: live?.value?.data.data || { Accommodation: []},
    bookingCom: bookingCom?.value?.data.data || []
  };
}

export function* fetchHotelAccommodationProductsSaga(
  action: ReturnType<typeof HotelAccommodationProductActions.fetchHotelAccommodationProductsRequestAction>
) {
  const selectedTa = yield select(AgentsSelectors.selectedTaSelector);
  const backendApi = makeBackendApi(selectedTa?.uuid);

  const isSr = yield select(AuthSelectors.isSR);
  const searchQuery: ISearchQuery = yield select(FastSearchSelectors.offersQuerySelector);

  // Default to todays date if there are no dates in the search query in redux
  const startDate = formatDate(searchQuery.startDate || Date.now());
  const endDate = formatDate(searchQuery.endDate || addDays(Date.now(), 14));

  // @ts-ignore legacy
  const guestAges: IGuestAgesItem[] = uniqBy(
    pathOr([], ['lodgings'], searchQuery).map(item => pick(['numberOfAdults', 'agesOfAllChildren'], item)),
    (x: IGuestAgesItem) => `${x.numberOfAdults}-${[...(x.agesOfAllChildren || [])].sort((a, b) => a - b)}`
  );

  try {
    // @ts-ignore the call is correct
    const res: GetRatesResponse = yield call(getRates,
      backendApi,
      isSr,
      action.hotelUuid,
      startDate,
      endDate,
      guestAges,
      searchQuery.clientCountryCode
    );

    yield put(
      HotelAccommodationProductActions.fetchHotelAccommodationProductsSuccessAction(
        res.static,
        res.live,
        res.bookingCom
      )
    );
  } catch (e) {
    console.error(e);
    yield put(HotelAccommodationProductActions.fetchHotelAccommodationProductsFailureAction(e.message));
  }
}

export function* watchHotelAccommodationProductsSaga() {
  yield takeLatest(
    [HotelAccommodationProductActions.FETCH_HOTEL_ACCOMMODATION_PRODUCTS_REQUEST],
    fetchHotelAccommodationProductsSaga
  );
}
