import { IPriceCheckReq } from 'services/BackendApi';
import { pick } from 'lodash-es';
import { handleRequestBooking } from '../helpers';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import { isLiveRatePricesAreDifferent } from 'utils/bookingBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { getHotelAccommodationProductsSelector } from 'store/modules/hotelAccommodationProducts';
import { useCallback, useState } from 'react';
import { paymentMethodSelector } from 'store/modules/bookingConfirmation/subdomains/payment/selectors';

export const useRequestBooking = ({
  bookingBuild,
  setRequestInProgress,
  backendApi,
  enqueueNotification,
  selectedCompanyMembership,
  bookingDomain,
  bbv1AddBookingError,
  bbv1ClearBookingErrors,
  containsLiveRates,
  clientCountryCode,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  allowBookingLiveRates,
  setIsLiveRatePricesModalOpen,
  setLiveRatePricesModalData,
  liveRatePricesModalData,
  taMarginAmountOverride,
}) => {
  const dispatch = useDispatch();
  const openLiveRatePricesModal = useCallback(data => {
    setIsLiveRatePricesModalOpen(true);
    setLiveRatePricesModalData(data);
  }, []);
  const accommodationProducts = useSelector(getHotelAccommodationProductsSelector);
  const paymentMethod = useSelector(paymentMethodSelector);

  const handlePriceCheck = async () => {
    if (!bookingBuild) {
      return;
    }

    setRequestInProgress(true);

    const priceCheckReq = ({
      Accommodation: bookingBuild.request.Accommodation.map(accomm => {
        return pick(accomm, [
          'uuid',
          'startDate',
          'endDate',
          'guestAges',
          'liveRate.externalMealPlanCode',
          'liveRate.externalRateId',
        ]);
      }),
    } as unknown) as IPriceCheckReq;

    let priceCheckRes;
    try {
      priceCheckRes = await backendApi.liveRatesPriceCheck(
        bookingBuild.request.hotelUuid,
        priceCheckReq,
        clientCountryCode
      );
    } catch (e) {
      setRequestInProgress(false);
      enqueueNotification({
        message: 'Error verifying Live Rate price. Please, try later to complete.',
        options: { variant: 'error' },
      });
      return;
    }

    const priceCheckAccommodations = priceCheckRes.data.data;

    if (isLiveRatePricesAreDifferent(bookingBuild.request.Accommodation, priceCheckAccommodations.Accommodation)) {
      const priceCheckData = priceCheckAccommodations.Accommodation.map((priceCheckAccom, index) => {
        return {
          liveRate: priceCheckAccom.liveRate,
          guestAges: priceCheckAccom.guestAges,
          prevPrice: bookingBuild.request.Accommodation[index].liveRate?.amount,
          newPrice: priceCheckAccom.priceCheck?.totalCents ?? null,
          title: accommodationProducts.find(item => item.uuid === priceCheckAccom.uuid)?.title,
          prevMealPlan:
            bookingBuild.response.availableProductSets.Accommodation[index].availableSubProductSets['Meal Plan'][0]
              .products[0].name,
          newMealPlan: priceCheckAccom.priceCheck?.externalMealPlanDescription ?? '',
        };
      });
      openLiveRatePricesModal(priceCheckData);
      setRequestInProgress(false);
      return;
    }

    try {
      await handleRequestBooking({
        bookingBuild,
        backendApi,
        bookingDomain,
        bbv1AddBookingError,
        bbv1ClearBookingErrors,
        clientCountryCode,
        selectedCompanyMembership,
        setShouldShowLeaveAlert,
        setRedirectLocation,
        paymentMethod: containsLiveRates ? paymentMethod : null,
        taMarginAmountOverride,
      });
    } catch (e) {
      setRequestInProgress(false);
      enqueueNotification({
        message: 'Error booking Live Rate. Please, try later to complete.',
        options: { variant: 'error' },
      });
    }
  };

  const handleBookButtonClick = async () => {
    setRequestInProgress(true);
    try {
      await handleRequestBooking({
        bookingBuild,
        backendApi,
        bookingDomain,
        bbv1AddBookingError,
        bbv1ClearBookingErrors,
        clientCountryCode,
        selectedCompanyMembership,
        setShouldShowLeaveAlert,
        setRedirectLocation,
        paymentMethod: containsLiveRates ? paymentMethod : null,
        taMarginAmountOverride,
      });
    } catch (e) {
      setRequestInProgress(false);
      enqueueNotification({
        message: 'Error creating booking. Please try later to complete.',
        options: { variant: 'error' },
      });
    }
  };

  const handleRequestToBookButton = () => {
    if (allowBookingLiveRates) {
      handlePriceCheck();
    } else {
      handleBookButtonClick();
    }
  };

  const handleLiveRatePricesModalConfirm = async () => {
    setRequestInProgress(true);
    setShouldShowLeaveAlert(false);
    try {
      await handleRequestBooking({
        bookingBuild,
        backendApi,
        bookingDomain,
        bbv1AddBookingError,
        bbv1ClearBookingErrors,
        // @ts-ignore-line
        liveRatePricesModalData,
        clientCountryCode,
        selectedCompanyMembership,
        setShouldShowLeaveAlert,
        setRedirectLocation,
        paymentMethod: containsLiveRates ? paymentMethod : null,
        taMarginAmountOverride,
      });
    } catch (e) {
      setRequestInProgress(false);
      enqueueNotification({
        message: 'Error booking Live Rate. Please, try later to complete.',
        options: { variant: 'error' },
      });
    }
  };

  const closeLiveRatePricesModal = useCallback(() => {
    setIsLiveRatePricesModalOpen(false);
    dispatch(
      BookingBuilderActions.bbv1AddBookingErrorAction({
        type: 'warn',
        detail:
          "THE PRICES DISPLAYED IN THE BASKET MAY NOT BE FINAL. ANY PRICE DIFFERENCE WILL BE CONFIRMED WITH YOU WHEN YOU SELECT 'BOOK'.",
      })
    );
  }, [BookingBuilderActions.bbv1AddBookingErrorAction]);

  return { handleRequestToBookButton, handleLiveRatePricesModalConfirm, closeLiveRatePricesModal };
};
