import React, { useCallback } from 'react';
import classNames from 'classnames';
import FluidButton from 'ui/FluidButton';
import { useDispatch } from 'react-redux';
import { openUploadAttachmentModalAction } from 'store/modules/taskManagement/actions';
import { TaskUploadFileModal } from './TaskUploadFileModal';

export interface ITaskAttachmentsProps {
  className?: string;
  taskId?: string;
}

export const TaskAttachments: React.FC<ITaskAttachmentsProps> = (props) => {
  const dispatch = useDispatch();

  const handleAttachFile = useCallback(() => {
    dispatch(openUploadAttachmentModalAction());
  }, [dispatch]);
  
  return (
    <div className={classNames(props.className, 'task-reminders flex flex-col items-start gap-[10px]')}>
      <p className="font-pt-sans text-black m-0 text-[16px]"><b>Attachments</b> (0)</p>
      <FluidButton type="secondary" onClick={handleAttachFile}>
        Attach File
      </FluidButton>
      <TaskUploadFileModal />
    </div>
  )
};
