import { createSelector } from 'reselect';
import { IActingOnBehalfOfDomain } from './model';
import { EUserType } from 'services/BackendApi';

export const domainSelector = (state: any): IActingOnBehalfOfDomain => state.actingOnBehalfOf;

export const actingOnBehalfOfUuidSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain) => domain.aoboUuid
);

export const actingOnBehalfOfRoleSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain) => domain.aoboRole
);

export interface IActingOnBehalfOfUser {
  uuid: string | null;
  role: EUserType | null;
}

export const actingOnBehalfOfUserSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain): IActingOnBehalfOfUser => {
    return {
      uuid: domain.aoboUuid,
      role: domain.aoboRole,
    };
  }
);
